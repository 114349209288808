body {
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 1rem;
	background-color: #fff;
	letter-spacing: 0.4px;
}

.slide-nav {
	font-size: 0.75rem;
}

.slider {
	display: block;
	width: 100%;
	height: 100vh;
}

.hero-image {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
	padding: 0px;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}

.slide {
	background-position: 0px 0px, 50% 20%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.h2-heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: Montserrat, sans-serif;
	font-size: 3rem;
	line-height: 3rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 1px;
	text-transform: capitalize;
}

.h2-heading.hero {
	max-width: none;
	margin-bottom: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-family: Rokkitt, sans-serif;
	color: #fff;
	font-size: 3.75rem;
	line-height: 4.5rem;
	font-style: normal;
	font-weight: 300;
	text-align: center;
	letter-spacing: 0px;
	text-transform: none;
}

.icon-7 {
	font-family: Montserrat, sans-serif;
	font-size: 2rem;
	font-weight: 100;
}

.hero-inner-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.hero-image-header {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
	margin-top: 5rem;
	margin-bottom: 4rem;
	margin-left: 0px;
	padding: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.icon-6 {
	font-family: Montserrat, sans-serif;
	font-size: 2rem;
	font-weight: 100;
}

.navbar {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: block;
	width: 100%;
	padding: 0rem 0rem 0.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.25);
	background-color: transparent;
}

.nav-link {
	padding: 1rem 1.5rem;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
}

.nav-link:hover {
	opacity: 0.65;
}

.nav-link.secondary-link {
	padding: 0.5rem 1.25rem;
	border: 1px none transparent;
	border-radius: 0px;
	font-family: Figtree, sans-serif;
	font-size: 0.95rem;
	font-weight: 400;
}

.nav-link.secondary-link:hover {
	border-style: none;
	border-width: 1px;
	border-color: #82bd0a;
	border-radius: 8px;
	opacity: 1;
	color: #fff;
}

.nav-link.secondary-link {
	padding: 1.1rem 1.23rem;
	border: 1px none transparent;
	border-radius: 0px;
	font-family: Figtree, sans-serif;
	font-size: 0.95rem;
	font-weight: 400;
}

.nav-link.secondary-link:hover {
	border-style: none;
	border-width: 1px;
	border-color: #82bd0a;
	border-radius: 8px;
	opacity: 1;
	color: #fff;
}

.search-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.search-form {
	display: block;
	width: 85%;
	margin-bottom: 0px;
}

.form {
	display: block;
	width: 100%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.select-field {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 80px;
	margin-bottom: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	float: left;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border: 1px none #000;
	border-radius: 0px;
	background-color: #fff;
	opacity: 1;
	font-family: Figtree, sans-serif;
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 400;
	cursor: pointer;
}

.text-field {
	width: 100%;
	height: 80px;
	margin-bottom: 0px;
	padding: 1rem 1rem 1rem 1.25rem;
	border-style: none;
	border-width: 1px;
	border-color: #000 rgba(0, 0, 0, 0.05) #000 #000;
	border-radius: 8px 0px 0px 8px;
	font-family: Figtree, sans-serif;
	font-size: 0.85rem;
	font-weight: 400;
}

.submit-button {
	display: block;
	width: 100%;
	height: 100%;
	margin-top: 0rem;
	margin-right: auto;
	margin-left: auto;
	float: left;
	border: 1px none #000;
	border-radius: 0px 8px 8px 0px;
	background-color: #82bd0a;
	background-image: var(--search-icon);
	background-position: 50% 50%;
	background-size: 27px;
	background-repeat: no-repeat;
	-webkit-transition: background-color 250ms ease;
	transition: background-color 250ms ease;
	font-family: Lato, sans-serif;
	color: #000;
	font-weight: 700;
	text-transform: uppercase;
}

.submit-button:hover {
	background-color: #6fa405;
}

.left-arrow {
	display: none;
}

.right-arrow {
	display: none;
}

.footer-section {
	display: block;
	border-top: 1px solid rgba(51, 51, 51, 0.08);
	background-color: transparent;
}

.footer-col-wrap {
	width: auto;
	min-width: auto;
	margin-right: 0rem;
	padding-right: 0rem;
}

.footer-col-wrap.connect {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-width: auto;
	margin-right: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.logo {
	display: inline-block;
	overflow: hidden;
	width: auto;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 0.4rem;
	float: none;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-bottom-style: none;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.logo:hover {
	color: #fff;
}

.logo.footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-right: 0px;
	margin-bottom: 0rem;
	margin-left: 0rem;
	padding-top: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.contact-link {
	margin-right: 0rem;
	margin-bottom: 0rem;
	margin-left: 0.5rem;
	padding: 0.25rem 0rem 0rem;
	border-bottom: 0.75px solid rgba(0, 0, 0, 0.1);
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 0.9rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.contact-link:hover {
	border-bottom-color: #fff;
	color: #00457b;
}

.footer-nav-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.image-2 {
	width: 175px;
}

.paragraph {
	width: auto;
	max-width: 1000px;
	margin-bottom: 2.25rem;
	padding-right: 0px;
	padding-left: 0px;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-align: center;
}

.paragraph.white {
	max-width: none;
	margin-bottom: 0rem;
	margin-left: 0.5rem;
	padding: 0.25rem 0rem;
	-webkit-align-self: flex-end;
	-ms-flex-item-align: end;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	align-self: flex-end;
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	text-align: right;
}

.container-block {
	display: block;
	max-width: 1270px;
	margin-right: auto;
	margin-left: auto;
}

.container-block.footer-container {
	position: static;
	left: 10%;
	display: block;
	width: 100%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding: 1.5rem 5rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.footer-column-rights {
	display: flex;
	width: 80%;
	margin-right: 0px;
	margin-left: 0px;
	padding-bottom: 0rem;
	justify-content: flex-start;
	align-items: center;
}

.grip-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 20%;
	margin-right: 0px;
	margin-left: 0px;
	padding-bottom: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-gray {
	color: #333;
}

.rights-container-block {
	position: static;
	left: 10%;
	display: flex;
	width: 100%;
	max-width: none;
	padding: 1rem 5rem;
	justify-content: space-between;
	align-items: stretch;
	border-top: 1px solid rgba(51, 51, 51, 0.05);
	background-color: rgba(51, 51, 51, 0.03);
}

.footer-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 0px 20px 0rem 0px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 0.75rem;
	font-weight: 400;
	text-align: left;
	text-transform: capitalize;
	cursor: pointer;
}

.footer-text:hover {
	border-bottom: 3px none #3e85ed;
	opacity: 0.75;
	color: #333;
	text-decoration: none;
}

.footer-text.rights {
	margin-bottom: 0px;
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 0.75rem;
	font-weight: 400;
	cursor: auto;
}

.footer-text.rights:hover {
	border-bottom-style: none;
	opacity: 1;
	color: #333;
}

.footer-text.rights h1 {
	margin: 0px;
	padding: 0px;
	display: inline;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	font-weight: inherit;
}

.nav-wrapper {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
}

.brand {
	margin-right: auto;
}

.nav-menu.secondary-nav {
	margin-right: 0px;
	margin-left: auto;
}

.nav-menu.secondary-nav {
	margin-right: 0px;
	margin-left: auto;
}

.top-header-nav-menu {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-left: auto;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.top-nav-link {
	padding: 1rem 1.25rem;
	-webkit-transition: background-color 250ms ease;
	transition: background-color 250ms ease;
	font-family: Figtree, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1.1rem;
	font-weight: 400;
}

.top-nav-link:hover {
	opacity: 1;
	color: #fff;
}

.main-menu-wrapper {
	position: relative;
	top: 0%;
	display: flex;
	padding-right: 2rem;
	padding-left: 3.25rem;
	justify-content: space-between;
	align-items: center;
}

.invisible-block {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	height: 100px;
	background-color: transparent;
}

.icon-9 {
	display: block;
	margin-right: 1rem;
	border: 1px none #000;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	font-size: 0.7rem;
	font-weight: 400;
}

.welcome-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 1rem;
	padding-left: 1rem;
	font-family: Figtree, sans-serif;
	color: #fff;
	font-size: 0.9rem;
	font-weight: 400;
	letter-spacing: 3px;
	text-transform: uppercase;
	text-align: center;
}

.welcome-text-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.divider-line {
	width: 35px;
	height: 1px;
	background-color: #ffa814;
}

.search-form-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-radius: 8px;
	background-color: #fff;
}

.search-field {
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.field-form-block {
	padding-right: 1rem;
	padding-left: 1rem;
	border-right: 1px solid rgba(51, 51, 51, 0.05);
}

.search-field,
.field-form-block {
	flex-grow: 1;
}

.search-submit-button {
	width: 10%;
}

.div-block-51 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.div-block-52 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.divider {
	width: 1px;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	background-color: rgba(51, 51, 51, 0.1);
}

.footer-icon {
	width: 15px;
}

.footer-contact-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-bar-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	padding: 0rem 1.75rem 0rem 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	background-color: transparent;
	color: #fff;
}

.hero-paragraph {
	max-width: 900px;
	margin-bottom: 0px;
	padding: 0.5rem 1rem 1rem;
	background-color: transparent;
	font-family: Figtree, sans-serif;
	color: #fff;
	font-size: 1.15rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-align: center;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: -1.5rem;
	margin-right: 1.5rem;
	padding-bottom: 1.5rem;
	border-right: 1px solid rgba(51, 51, 51, 0.1);
}

.inside-img-wrap {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 375px;
}

.inside-img-wrap.inside-page {
	width: 100%;
	height: 250px;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1400px;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding: 3rem 15px 4rem;
	border-top: 1px none #000;
	background-color: #fff;
}

.button-styling {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-left: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.table-div {
	font-family: Figtree, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
}

.inside-page-breadcrumb-wrap {
	display: inline-block;
	margin-bottom: 1.5rem;
	padding-bottom: 0rem;
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.625rem;
}

h1,
.heading-6 {
	font-size: 2.5rem;
	line-height: 2.75rem;
	margin-top: 0;
	font-family: Rokkitt, sans-serif;
	font-weight: 300;
}

h1 a {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	transition: all 0.2s ease;
	font-family: Rokkitt, sans-serif;
	color: #00457b;
	line-height: 2.25rem;
	font-weight: 400;
	text-decoration: none;
}

h1 a:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #00457b;
}

h2,
h3,
h4,
.heading-18 {
	margin-bottom: 5px;
	font-family: Rokkitt, sans-serif;
	color: #333;
	font-weight: 400;
}

h2 {
	font-size: 2rem;
	line-height: 2.25rem;
	margin-top: 1.25rem;
}

h2 a,
.h2-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rokkitt, sans-serif;
	color: #00457b;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 300;
	text-decoration: none;
}

h2 a:hover,
.h2-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #00457b;
}

h3 {
	font-size: 1.5rem;
	line-height: 1.875rem;
	margin-top: 1.25rem;
}

h3 a,
.h3-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rokkitt, sans-serif;
	color: #00457b;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

h3 a:hover,
.h3-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #00457b;
}

h4 {
	font-size: 1.125rem;
	line-height: 1.5rem;
	margin-top: 0.625rem;
}

h4 a,
.h4-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rokkitt, sans-serif;
	color: #00457b;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

h4 a:hover,
.h4-header-link:hover {
	border-bottom-width: 2px;
	border-bottom-color: transparent;
	background-color: transparent;
	font-family: Rokkitt, sans-serif;
	color: #00457b;
	font-weight: 400;
}

h5,
h6,
.heading-10 {
	margin-bottom: 5px;
	font-family: Rokkitt, sans-serif;
	color: #333;
	font-weight: 400;
	text-transform: uppercase;
}

h5 {
	font-size: 0.875rem;
	line-height: 1.25rem;
	margin-top: 0.625rem;
}

h5 a,
.h5-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rokkitt, sans-serif;
	color: #00457b;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

h5 a:hover,
.h5-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #00457b;
}

h6 {
	font-size: 0.75rem;
	line-height: 1.125rem;
	margin-top: 0.625rem;
}

h6 a,
.h6-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Rokkitt, sans-serif;
	color: #00457b;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

h6 a:hover,
.h6-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #00457b;
}

.left-nav-column {
	display: block;
	padding-right: 0px;
	padding-left: 0px;
}

.main-content-column {
	padding-right: 0px;
	padding-left: 0px;
}

.unordered-list {
	margin-bottom: 1rem;
}

.breadcrumb-list {
	margin: 0rem 0.125rem;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

blockquote,
.block-quote {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #ffa814;
	background-color: rgba(255, 168, 20, 0.1);
	font-family: Figtree, sans-serif;
	color: #5b5b5b;
	font-size: 1.3rem;
	line-height: 1.65rem;
	font-weight: 400;
}

.main-content-section {
	max-width: none;
	margin-right: 1.5rem;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem;
	border: 2px none transparent;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.85em;
	line-height: 1.375em;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-nested-list-link:hover {
	border: 2px none #48bdd7;
	background-color: transparent;
	background-image: none;
	color: #0915f8;
}

.left-nav-nested-list-link.w--current {
	color: #5e8dbe;
}

.left-nav-nested-list-link.w--current:hover {
	color: #0915f8;
}

.left-nav-nested-list-link.grandchild {
	border-style: none;
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Figtree, sans-serif;
	color: #333;
	font-weight: 400;
}

.left-nav-nested-list-link.grandchild:hover {
	background-color: rgba(51, 51, 51, 0.03);
	background-image: none;
	box-shadow: inset 19px 0 0 -16px #82bd0a;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #333;
}

.left-nav-nested-list-link.grandchild.w--current {
	margin-left: 1rem;
	background-color: rgba(51, 51, 51, 0.03);
	background-image: none;
	box-shadow: inset 19px 0 0 -16px #82bd0a;
}

.left-nav-nested-list-link.grandchild.w--current:hover {
	color: #333;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 0.825em;
	font-weight: 400;
}

.left-nav-nested-list-link.great-grandchild.w--current {
	margin-left: 2.25rem;
	background-color: rgba(51, 51, 51, 0.03);
	box-shadow: inset 9px 0 0 -6px #82bd0a;
}

.left-nav-nested-list-link.great-grandchild:hover {
	color: #82bd0a;
}

.left-nav-nested-list-link.great-grandchild.current {
	margin-left: 2.25rem;
	background-color: rgba(51, 51, 51, 0.03);
	box-shadow: inset 9px 0 0 -6px #82bd0a;
}

.breadcrumb-nav-link {
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.breadcrumb-nav-link:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.left-nav-list-link {
	display: block;
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	border-bottom-style: none;
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Figtree, sans-serif;
	color: #333;
	font-weight: 300;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-list-link:hover {
	background-color: rgba(51, 51, 51, 0.03);
	box-shadow: inset 19px 0 0 -16px #82bd0a;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #333;
	text-decoration: none;
}

.left-nav-list-link.w--current {
	background-color: rgba(51, 51, 51, 0.03);
	background-image: none;
	box-shadow: inset 19px 0 0 -16px #82bd0a;
	color: #333;
}

.content-container {
	display: block;
	width: 50%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.content-container.threequarterwidth {
	width: 100%;
	max-width: none;
	padding-right: 0rem;
	padding-left: 0rem;
}

.ordered-list-2 {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.intro-paragraph {
	margin-bottom: 1rem;
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.paragraph-text {
	margin-bottom: 1rem;
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.paragraph-text.list-item {
	margin-bottom: 0rem;
}

.left-nav-nested-list-item {
	margin-bottom: 0px;
	border-bottom: 1px none rgba(51, 51, 51, 0.08);
}

.left-nav-nested-list-item.great-grandchild {
	border-bottom-style: solid;
}

.left-nav-nested-list-item.grandchild {
	border-bottom-style: solid;
}

.paragraph-12 {
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.primary,
.primary-button {
	border-radius: 5px;
	background-color: #fff;
	-webkit-transition: background-color 350ms ease;
	transition: background-color 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.75rem;
	font-weight: 500;
	letter-spacing: 1px;
}

.primary:hover,
.primary-button:hover {
	background-color: #0915f8;
	color: #fff;
}

.primary,
.primary-button.inside-page {
	margin-right: 20px;
	padding-top: 12px;
	padding-bottom: 12px;
	border-style: solid;
	border-width: 1.5px;
	border-color: #00457b;
	background-color: #00457b;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Figtree, sans-serif;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 400;
	letter-spacing: 0px;
}

.primary:hover,
.primary-button.inside-page:hover {
	border-style: solid;
	border-color: #01345c;
	background-color: #01345c !important;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 2px none #498fa3;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	padding-left: 0px;
	box-shadow: none;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.main-content-wrapper {
	display: block;
	width: 95%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.text-block-21 {
	font-family: Figtree, sans-serif;
	color: #00457b;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: capitalize;
}

.horiz-divider-2 {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: #82bd0a;
	color: #003c43;
}

.left-nav-list-item {
	margin-bottom: 0rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.inside-page-bg-img {
	position: relative;
	height: 250px;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(22, 22, 22, 0.5)), to(rgba(22, 22, 22, 0.5))), var(--header-image);
	background-image: linear-gradient(180deg, rgba(22, 22, 22, 0.5), rgba(22, 22, 22, 0.5)), var(--header-image);
	background-position: 0px 0px, 50% 45%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.paragraph-16 {
	font-family: Montserrat, sans-serif;
}

.right-sidebar-column {
	margin-top: 0px;
	padding-left: 0rem;
	border-top: 2px solid #ffa814;
}

.right-sidebar-title {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: Rokkitt, sans-serif;
	font-size: 20px;
	font-weight: 400;
}

.sidebar-section-wrap {
	position: relative;
	margin-top: 1.15rem;
	margin-bottom: 1.15rem;
	margin-left: 0rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.sidebar-list-item {
	margin-bottom: 0.25rem;
	padding-left: 0px;
	font-family: Montserrat, sans-serif;
	color: #0915f8;
	text-decoration: none;
}

.sidebar-list {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 0em;
}

.right-sidebar-link {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-family: Figtree, sans-serif;
	color: #00457b;
	line-height: 1.15rem;
	font-weight: 400;
	text-decoration: none;
}

.right-sidebar-link:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.right-sidebar-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.inside-img {
	height: 100%;
	background-image: var(--inside-image);
	background-position: 50% 50%;
	background-size: cover;
}

.section-title {
	margin-bottom: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	font-family: Rokkitt, sans-serif;
	color: #333;
	font-size: 2.15rem;
	font-weight: 400;
}

.left-nav-nested-list-item-wrapper {
	margin-bottom: 0px;
	border-bottom: 1px none rgba(51, 51, 51, 0.08);
}

.secondary,
.secondary-button {
	padding-top: 12px;
	padding-bottom: 12px;
	border-style: solid;
	border-width: 1.5px;
	border-color: #82bd0a;
	border-radius: 5px;
	background-color: #fff;
	-webkit-transition: background-color 350ms ease;
	transition: background-color 350ms ease;
	font-family: Figtree, sans-serif;
	color: #333;
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 400;
}

.secondary:hover,
.secondary-button:hover {
	background-color: #82bd0a;
	color: #fff;
}

.secondary,
.secondary-button.inside-page {
	margin-right: 20px;
	background-color: #82bd0a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2b2b2b;
}

.secondary:hover,
.secondary-button.inside-page:hover {
	background-color: #6fa405;
	color: #fff;
}

.main-nav-link,
.top-header-nav-link {
	padding: 1rem 1.25rem 0.35rem 1.25rem;
	-webkit-transition: background-color 250ms ease;
	transition: background-color 250ms ease;
	font-family: Figtree, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1.1rem;
	font-weight: 400;
}

.main-nav-link,
.top-header-nav-link:hover {
	opacity: 1;
	color: #fff;
}

.main-nav-link,
.top-header-nav-link.contact-us {
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

.mobile-nav-link {
	display: none;
	padding-top: 20px;
	padding-bottom: 5px;
	padding-left: 0px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #196ab5;
	font-size: 27px;
	line-height: 30px;
	font-weight: 700;
}

.mobile-nav-link:hover {
	color: #ca4949;
}

.nav-link-3 {
	display: inline-block;
	margin-right: 1px;
	padding: 1rem 0.5rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	box-shadow: none;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.375rem;
	font-weight: 800;
	text-align: center;
	text-transform: uppercase;
}

.nav-link-3:hover {
	background-color: transparent;
	box-shadow: inset 0 -3px 0 0 #3128b1;
	color: #3128b1;
}

.nav-link-3.w--current {
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -3px 0 0 #fff;
	color: #fff;
}

.nav-link-3.w--current:hover {
	background-color: rgba(0, 0, 0, 0.1);
	color: #fff;
}

.inside-navbar {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: block;
	width: 100%;
	padding: 0rem 0rem 0.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-bottom: 1px solid transparent;
	background-color: transparent;
}

.dropdown-link-2 {
	margin-bottom: 0.5rem;
	padding: 0.5rem 0rem 0.125rem;
	border-bottom: 1px none transparent;
	border-left: 3px none #fff;
	-webkit-transition: border-color 250ms ease;
	transition: border-color 250ms ease;
	font-family: Figtree, sans-serif;
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 400;
}

.dropdown-link-2:hover {
	display: inline-block;
	margin-bottom: 0.5rem;
	padding-right: 0rem;
	padding-bottom: 0.125rem;
	padding-left: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	border-left: 3px none #00457b;
	border-bottom-style: none;
	border-bottom-color: #00457b;
	color: #ffa814 !important;
	text-decoration: none;
}

.categories-text {
	display: inline-block;
	margin-right: 1.25rem;
	margin-left: 1.25rem;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.15) !important;
	opacity: 1;
	font-family: Figtree, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	font-weight: 400;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	margin: 17.6px 20px;
}

.dropdown-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.5rem 2rem 0.5rem 1.25rem;
	justify-content: flex-start;
	border: 1px none transparent;
	border-radius: 0px;
	font-family: Figtree, sans-serif;
	color: #fff;
	font-size: 0.95rem;
	font-weight: 400;
	letter-spacing: 0.5px;
}

.dropdown-toggle:hover {
	border-style: none;
	border-color: #82bd0a;
	color: #fff;
}

.dropdown-list-2 {
	background-color: #fff;
}

.dropdown-list-2.w--open {
	top: auto;
	padding: 0.5rem 2rem 0.5rem 1.5rem;
	border-radius: 8px;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.main-nav {
	/*position: fixed;*/
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: block;
	width: 100%;
	padding: 0rem 0rem 0.5rem;
	justify-content: space-between;
	border-bottom: 1px none hsla(0, 0%, 100%, 0.25);
	background-color: transparent;
}

.inside-pages-invisible-block {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	height: 100px;
	background-color: transparent;
}

.tertiary-button {
	padding-top: 12px;
	padding-bottom: 12px;
	border-style: solid;
	border-width: 1.5px;
	border-color: #e84a36;
	border-radius: 5px;
	background-color: #e84a36;
	transition: all 200ms ease;
	font-family: Figtree, sans-serif;
	color: #2b2b2b;
	font-size: 0.85rem;
	line-height: 1rem;
	font-weight: 400;
}

.tertiary-button:hover {
	background-color: #dc2e18;
	color: #fff;
}

.mobile-navigation-menu {
	display: none;
}

.mm-navbar.mm-navbar_sticky {
	display: none;
}

.translucent-bg .main-nav {
	background-color: #fff;
	transition: ease-in-out 250ms all;
	z-index: 300;
}

.translucent-bg .main-nav.is-homepage {
	background-color: rgba(255, 255, 255, 0.1);
}

.translucent-bg .main-nav #secondary-nav .categories-text,
.translucent-bg .main-nav #secondary-nav .secondary-link .secondary-nav-link {
	color: rgba(51, 51, 51);
}

.translucent-bg .main-nav.is-homepage #secondary-nav:hover .secondary-link .secondary-nav-link {
	color: rgba(255, 255, 255, 0.6);
}

.translucent-bg .main-nav.is-homepage #secondary-nav .secondary-link .secondary-nav-link:hover {
	color: #fff;
}

.translucent-bg .main-nav.is-homepage #secondary-nav .categories-text,
.translucent-bg .main-nav.is-homepage #secondary-nav .secondary-link .secondary-nav-link {
	color: #fff;
}

.translucent-bg .categories-text {
	border-bottom: 1px solid rgba(51, 51, 51) !important;
}

.translucent-bg .main-nav.is-homepage .categories-text {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.15) !important;
}

.logo-img {
	width: 225px;
	max-width: unset;
	margin-top: 10px;
	margin-bottom: 10px;
}

@media screen and (max-width: 1550px) {
	.nav-menu .categories-text {
		display: none;
	}
}

@media screen and (max-width: 1400px) {
	.main-menu-wrapper {
		padding-right: 1rem;
		padding-left: 1.75rem;
	}

	.nav-menu .categories-text {
		display: none;
	}

	.nav-menu .nav-link.secondary-link {
		padding-left: 0.5rem;
		margin-right: 5px;
	}
}

@media screen and (max-width: 1240px) {
	.main-menu-wrapper .logo-img {
		width: 125px;
	}

	.nav-menu.secondary-nav {
		padding-left: 20px;
	}

	.nav-menu .categories-text {
		display: none;
	}

	.nav-menu .nav-link.secondary-link {
		padding-left: 0.3rem;
		margin-right: 2px;
		padding-right: 0.9rem;
	}

	.w-icon-dropdown-toggle {
		top: 3px;
	}
}

@media screen and (max-width: 1294px) {
	.translucent-bg .main-nav {
		height: 130px;
	}
}

@media screen and (max-width: 991px) {
	body {
		padding-top: 40px;
	}

	.logo-img {
		width: 190px;
	}

	.translucent-bg .main-nav {
		height: 75px;
	}

	.mobile-navigation-menu {
		display: block;
	}

	.slider {
		height: 90vh;
	}

	.hero-image {
		height: 90vh;
		padding-right: 3rem;
		padding-left: 3rem;
		align-items: stretch;
	}

	.h2-heading.hero {
		max-width: none;
		margin-bottom: 0.5rem;
		font-size: 3.5rem;
		line-height: 3.15rem;
		font-weight: 300;
		text-align: center;
	}

	.icon-7 {
		font-size: 1.8rem;
	}

	.hero-inner-content {
		width: auto;
	}

	.hero-image-header {
		margin-top: 3rem;
		margin-bottom: 1rem;
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.icon-6 {
		font-size: 1.8rem;
	}

	.navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		background-color: #fff;
	}

	.nav-link.secondary-link {
		display: none;
	}

	.nav-link.secondary-link {
		display: none;
	}

	.search-form {
		width: 80%;
	}

	.select-field {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 55px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.text-field {
		height: 55px;
		padding-left: 1.25rem;
		font-weight: 300;
	}

	.submit-button {
		width: 100%;
		border-top-right-radius: 0px;
		border-bottom-left-radius: 8px;
		background-size: 25px;
	}

	.footer-col-wrap {
		width: 48%;
		min-width: 0px;
		margin-top: 0.5rem;
		margin-right: 0rem;
		padding-bottom: 1rem;
	}

	.footer-col-wrap.connect {
		width: 100%;
		margin-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.logo {
		width: 180px;
		height: 100%;
		margin: 0rem 0px;
		padding-top: 0rem;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.logo.footer {
		margin-bottom: 0rem;
	}

	.contact-link {
		display: block;
		margin-right: 0.5rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		font-size: 0.85rem;
	}

	.footer-nav-wrapper {
		width: 100%;
		padding-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom: 1px none rgba(250, 251, 252, 0.05);
	}

	.paragraph {
		width: auto;
		max-width: 650px;
	}

	.paragraph.white {
		font-size: 0.85rem;
		line-height: 1rem;
	}

	.container-block.footer-container {
		left: 5%;
		padding: 1rem 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-column-rights {
		width: 75%;
		padding-left: 0vw;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.grip-link {
		width: 25%;
		padding-right: 0vw;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.rights-container-block {
		left: 5%;
		padding: 1rem 2rem;
	}

	.footer-text {
		margin-right: 15px;
		margin-bottom: 0px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-text.rights {
		margin-right: 15px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.nav-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.nav-menu.secondary-nav {
		top: auto;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		background-color: #fff;
	}

	.nav-menu.secondary-nav {
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
		background-color: #fff;
	}

	.main-menu-wrapper {
		position: static;
		padding-top: 0rem;
		padding-left: 2rem;
	}

	.search-form-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.search-field {
		width: 100%;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		border-right: none;
		border-top-right-radius: 8px;
		overflow: hidden;
	}

	.field-form-block {
		width: 100%;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}

	.search-submit-button {
		width: 100%;
		height: 55px;
	}

	.div-block-51 {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom: 1px none hsla(0, 0%, 85.9%, 0.15);
	}

	.two-part-button-wrap:hover {
		background-color: transparent;
	}

	.two-part-button-toggle {
		width: 10%;
		margin-bottom: 0px;
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
		float: right;
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
		border-left: 1px solid rgba(51, 51, 51, 0.05);
		background-color: transparent;
		background-image: var(--gray-arrow-icon);
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		color: transparent;
		font-size: 11px;
		line-height: 20px;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: transparent;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.625em;
		margin-right: 0.3125em;
		background-image: var(--white-arrow-icon);
		background-position: 0px 50%;
		background-size: 11px 11px;
		background-repeat: no-repeat;
		opacity: 1;
		color: transparent;
		text-align: center;
	}

	.dropdown {
		display: none;
	}

	.div-block-52 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.header-bar-wrapper {
		padding: 0rem 0rem 0rem 2rem;
		border-bottom-style: none;
		border-bottom-color: rgba(51, 51, 51, 0.15);
	}

	.hero-paragraph {
		margin-bottom: 1rem;
		padding: 0.5rem;
		font-size: 1rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.inside-img-wrap {
		width: 100%;
		height: 300px;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding: 40px;
	}

	.inside-page-breadcrumb-wrap {
		margin-bottom: 1rem;
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.content-container {
		width: 100%;
		padding-left: 0rem;
	}

	.content-container.threequarterwidth {
		width: 100%;
		padding-left: 0rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.main-content-wrapper {
		width: 100%;
	}

	.inside-page-bg-img {
		height: 250px;
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.right-sidebar-column {
		margin-top: 0rem;
		padding-top: 0rem;
		border-top: 2px solid #ffa814;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.mobile-nav-link {
		display: block;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 1.5rem;
		border-top: 1px none rgba(51, 51, 51, 0.05);
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
		border-left: 5px none #fff;
		-webkit-transition-property: border-color;
		transition-property: border-color;
		font-family: Figtree, sans-serif;
		color: #333;
		font-size: 0.9rem;
		line-height: 1.15rem;
		font-weight: 400;
		text-transform: none;
	}

	.mobile-nav-link:hover {
		padding-right: 0px;
		padding-left: 1.5rem;
		border-left-style: none;
		border-left-color: #ffa814;
		background-color: transparent;
		color: #ffa814;
	}

	.mobile-nav-link.top-mobile-nav-links {
		border-left: 5px none #fff;
		border-top-style: solid;
		font-family: Figtree, sans-serif;
		font-size: 0.9rem;
	}

	.mobile-nav-link.top-mobile-nav-links:hover {
		border-left: 5px none #ffa814;
		background-color: transparent;
		color: #ffa814;
	}

	.mobile-nav-link.top-mobile-link {
		background-color: rgba(51, 51, 51, 0.05);
	}

	.nav-link-3 {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1em 1rem 1em 1.5rem;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.1);
		-webkit-transition: box-shadow 200ms ease, background-color 200ms ease, color 200ms ease;
		transition: box-shadow 200ms ease, background-color 200ms ease, color 200ms ease;
		font-family: Figtree, sans-serif;
		color: #fff;
		font-size: 0.9rem;
		line-height: 1.15rem;
		font-weight: 400;
		text-align: left;
		text-decoration: none;
		text-transform: capitalize;
	}

	.nav-link-3:hover {
		padding-right: 0.75rem;
		padding-left: 1.5rem;
		background-color: rgba(250, 251, 252, 0.05);
		box-shadow: none;
		color: #ffa814;
	}

	.nav-link-3.w--current {
		background-color: transparent;
		box-shadow: inset 5px 0 0 0 #3128b1, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.nav-link-3.w--current:hover {
		background-color: transparent;
		color: #fff;
	}

	.nav-link-3.mobile-list-back {
		padding-left: 0.5625rem;
		background-color: rgba(250, 251, 252, 0.05);
		box-shadow: none;
		font-family: Figtree, sans-serif;
		color: #fff;
		font-size: 0.9rem;
		line-height: 1.15rem;
		text-transform: capitalize;
		cursor: pointer;
	}

	.nav-link-3.mobile-list-back:hover {
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.nav-link-3.two-part-mobile {
		width: 90%;
		margin-bottom: 0px;
		padding: 20px 2rem 20px 1.5rem;
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
		border-left: 5px none #fff;
		box-shadow: none;
		-webkit-transition-property: none;
		transition-property: none;
		font-family: Figtree, sans-serif;
		color: #333;
		font-size: 0.9rem;
		line-height: 1.15rem;
		font-weight: 400;
		text-decoration: none;
		text-transform: none;
	}

	.nav-link-3.two-part-mobile:hover {
		width: 90%;
		padding-left: 1.5rem;
		-webkit-box-flex: 0;
		-webkit-flex-grow: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		border-left: 5px none #ffa814;
		border-bottom-color: rgba(51, 51, 51, 0.03);
		background-color: transparent;
		box-shadow: none;
		color: #ffa814;
		font-style: normal;
	}

	.two-part-button-list-2 {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #00457b;
	}

	.inside-navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-color: rgba(51, 51, 51, 0.08);
		background-color: #fff;
	}

	.menu-icon {
		color: #00457b;
		font-size: 2rem;
	}

	.categories-text {
		display: none;
	}

	.mobile-menu {
		margin-left: auto;
		padding-right: 2rem;
		color: #fff;
	}

	.mobile-menu.w--open {
		background-color: transparent;
	}

	.main-nav {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		background-color: #fff;
	}

	.categoriesContainer {
		margin-bottom: 60px;
	}
}

@media screen and (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.hero-image {
		padding-right: 3rem;
		padding-left: 3rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slide {
		background-position: 0px 0px, 50% 20%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.h2-heading.hero {
		font-size: 3rem;
		line-height: 2.75rem;
	}

	.hero-image-header {
		margin-top: 50px;
		margin-bottom: 1rem;
	}

	.search-form {
		width: 65%;
	}

	.select-field {
		height: 45px;
	}

	.text-field {
		height: 45px;
		padding-left: 1.25rem;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0px;
	}

	.submit-button {
		width: 100%;
		height: 100%;
		background-size: 22px;
	}

	.footer-col-wrap {
		width: 60%;
		min-width: 200px;
		padding-right: 0rem;
	}

	.footer-col-wrap.connect {
		width: 100%;
		min-width: 175px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.logo {
		height: 100%;
		margin: 0rem 0px;
		padding-top: 0.5rem;
		padding-left: 0px;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		-ms-grid-row-align: stretch;
		align-self: stretch;
	}

	.footer-nav-wrapper {
		width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.paragraph.white {
		max-width: 300px;
	}

	.container-block.footer-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-column-rights {
		width: 70%;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.grip-link {
		width: 30%;
	}

	.rights-container-block {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-text {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.brand {
		padding-left: 0px;
	}

	.main-menu-wrapper {
		padding-left: 1rem;
	}

	.search-field {
		width: 100%;
	}

	.field-form-block {
		width: 100%;
	}

	.search-submit-button {
		height: 45px;
	}

	.two-part-button-toggle {
		width: 15%;
	}

	.hero-paragraph {
		margin-bottom: 0.25rem;
		font-size: 0.9rem;
		line-height: 1.3rem;
	}

	.inside-content-beckground {
		padding: 30px;
	}

	.inside-page-breadcrumb-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	h2 a,
	.h2-header-link {
		font-size: 1.75rem;
	}

	h3 a,
	.h3-header-link {
		font-size: 1.5rem;
	}

	h4 a,
	.h4-header-link {
		font-size: 1.25rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	blockquote,
	.block-quote {
		font-size: 1.125rem !important;
		line-height: 1.5rem;
	}

	.main-content-section {
		margin-right: 0rem;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
	}

	.ordered-list-2 {
		padding-left: 20px;
	}

	.intro-paragraph {
		font-size: 1.125rem;
	}

	h5 a,
	.h5-header-link {
		font-size: 1.125rem;
	}

	.inside-page-bg-img {
		min-height: 80px;
	}

	.right-sidebar-column {
		display: block;
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.nav-link-3.two-part-mobile {
		width: 85%;
	}

	.mobile-menu {
		padding-right: 1rem;
	}
}

@media screen and (max-width: 479px) {
	.logo-img {
		width: 180px;
		max-width: unset;
	}

	.hero-image {
		height: 100vh;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.slide {
		background-position: 0px 0px, 50% 20%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.h2-heading.hero {
		max-width: none;
		margin-bottom: 0.5rem;
		font-size: 2rem;
		line-height: 2rem;
		font-weight: 300;
		text-align: center;
	}

	.icon-7 {
		margin-right: 10px;
	}

	.hero-image-header {
		margin-top: 0rem;
		margin-bottom: 0.75rem;
	}

	.icon-6 {
		margin-left: 10px;
	}

	.search-form {
		width: 100%;
		margin-bottom: 10px;
	}

	.select-field {
		display: block;
		height: 40px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: 0.8rem;
	}

	.text-field {
		height: 40px;
		padding-left: 0.75rem;
		font-size: 0.8rem;
	}

	.submit-button {
		margin-top: 0rem;
		background-size: 18px;
	}

	.footer-col-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		min-width: 0px;
		padding-right: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-col-wrap.connect {
		margin-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.logo {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 140px;
		height: 100%;
		margin: 0rem;
		padding-top: 0rem;
	}

	.logo.footer {
		width: auto;
		margin-bottom: 1rem;
		margin-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.contact-link {
		margin-right: 0rem;
		margin-left: 0.5rem;
		padding-right: 0rem;
		padding-left: 0rem;
		background-image: none;
		line-height: 1rem;
	}

	.footer-nav-wrapper {
		padding-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.image-2 {
		width: 200px;
	}

	.paragraph {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.paragraph.white {
		max-width: none;
		margin-top: 0.25rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
		background-image: none;
		font-size: 0.85rem;
		line-height: 1rem;
		text-align: center;
	}

	.container-block.footer-container {
		padding-top: 1rem;
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.footer-column-rights {
		width: 100%;
		margin-right: auto;
		margin-bottom: 0.5rem;
		margin-left: auto;
		padding-bottom: 0.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.grip-link {
		width: 100%;
		padding-right: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.rights-container-block {
		display: block;
		padding-right: 1.5rem;
		padding-bottom: 1rem;
		padding-left: 1.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-text {
		margin-right: 10px;
		margin-left: 10px;
		text-align: left;
	}

	.footer-text.rights {
		text-align: center;
	}

	.welcome-text {
		font-size: 0.8rem;
	}

	.divider-line {
		width: 15px;
	}

	.field-form-block {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.search-submit-button {
		height: 40px;
	}

	.div-block-51 {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.div-block-52 {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-icon {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.footer-icon.location {
		width: 25px;
	}

	.footer-contact-wrapper {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.header-bar-wrapper {
		padding-right: 0rem;
		padding-left: 1rem;
	}

	.hero-paragraph {
		padding: 0.25rem;
		background-color: transparent;
		font-size: 0.85rem;
		line-height: 1.1rem;
	}

	.inside-img-wrap {
		width: 100%;
		height: 300px;
	}

	.inside-content-beckground {
		padding: 20px;
	}

	.button-styling {
		height: 175px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.intro-paragraph {
		line-height: 1.5rem;
	}

	p,
	.paragraph-text {
		font-size: 0.9rem;
	}

	.paragraph-12 {
		font-size: 0.9rem;
	}

	.text-block-21 {
		font-size: 0.8rem;
	}

	.right-sidebar-column {
		margin-top: 1rem;
		padding-top: 1rem;
	}

	.right-sidebar-link {
		border-bottom-width: 0.5px;
	}

	.nav-link-3.two-part-mobile:hover {
		width: 85%;
	}
}

/* Custom styles */
#print-header {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
}

#print-header,
#print-footer {
	display: none;
}

.left-nav-section-title a {
	border-bottom: none;
}

.main-nav-link {
	padding: 0 !important;
}

.secondary-nav-link {
	padding: 0 !important;
}

.w-nav {
	position: fixed;
}

.primary {
	display: inline-block;
	padding: 9px 15px !important;
	border: 0;
	text-decoration: none;
	cursor: pointer;
}

.nav-menu {
	top: auto;
	background-color: transparent;
}

.w-icon-dropdown-toggle {
	margin-right: 0;
}

li.hasChildren {
	margin-right: 15px; /* This is approximately the size of the dropdown toggle icon - the icon wasn't taking up space, so this is used to simulate that space */
}

.dropdown-list-2.w-dropdown-list {
	padding-left: 10px;
	padding-right: 10px;
	list-style: none;
	border-radius: 5px;
}

.hasChildren .dropdown-list-2.w-dropdown-list {
	margin-top: 1rem;
	padding: 0.5rem 1.5rem;
}

.nav-link.hasChildren:hover .dropdown-list-2.w-dropdown-list {
	display: block;
	box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 15%);
	z-index: 10;
}

.hasChildren .dropdown-list-2.w-dropdown-list a {
	padding: 0.5rem 0rem 0.125rem;
	color: #222;
}

#secondary-nav {
	padding-top: 0.5rem;
}

#secondary-nav .secondary-link:last-of-type ul.dropdown-list-2 {
	right: 0;
}

#primary-nav .top-header-nav-menu:hover li .main-nav-link,
#secondary-nav:hover .secondary-link,
#secondary-nav:hover .w-icon-dropdown-toggle {
	color: rgba(255, 255, 255, 0.6);
	transition: color 0.3s;
}

.translucent-bg .main-nav #secondary-nav:hover .secondary-link {
	color: rgba(51, 51, 51, 0.6);
}

.translucent-bg .main-nav #secondary-nav .secondary-link .secondary-nav-link:hover {
	color: rgba(51, 51, 51);
}

.inner-page .translucent-bg .main-nav #secondary-nav .secondary-link .secondary-nav-link:hover {
	color: rgba(51, 51, 51);
}

#primary-nav .top-header-nav-menu li:hover .main-nav-link,
#secondary-nav li:hover .secondary-link,
#secondary-nav li:hover .w-icon-dropdown-toggle {
	color: #fff;
}

.inner-page #primary-nav .top-header-nav-menu li .main-nav-link,
.inner-page #primary-nav .top-header-nav-menu li .w-icon-dropdown-toggle,
.inner-page #secondary-nav li .w-icon-dropdown-toggle,
.inner-page #secondary-nav li .secondary-link {
	color: rgba(255, 255, 255, 0.6);
}

.inner-page #primary-nav .top-header-nav-menu li:hover .main-nav-link,
.inner-page #primary-nav .top-header-nav-menu li:hover .w-icon-dropdown-toggle,
.inner-page #secondary-nav li:hover .w-icon-dropdown-toggle,
.inner-page #secondary-nav li:hover .secondary-link {
	color: #fff;
}

.inner-page #primary-nav .top-header-nav-menu li a.w--current,
.inner-page #secondary-nav li a.w--current {
	color: #fff;
}

.inner-page #secondary-nav li a.dropdown-link-2.w--current {
	color: #ffa814;
}


.translucent-bg .inner-page #secondary-nav li .w-icon-dropdown-toggle {
	color: rgba(51, 51, 51);
}

.inner-page .main-nav {
	border-bottom: 1px solid rgba(51, 51, 51, 0.08);
}

.checkbox_wrapper label {
	font-weight: unset;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

#GRIPFooterLogoText {
	margin-bottom: -5px !important;
}

.mm-panel {
	background: #fff;
}

.mm-listitem {
	border: 1px solid rgba(51, 51, 51, 0.05);
}

.breadcrumb-nav-link.w--current {
	color: #00457b;
	border-bottom: none;
}

.success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border-left: 4px solid #006953;
	background-color: #afd8cf;
	font-size: 1.125rem;
}

.error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border-left: 4px solid #d82b38;
	background-color: #f5d1d1;
	font-size: 1.125rem;
}

a.secondary {
	padding-left: 6px;
	padding-right: 6px;
	text-decoration: none;
}

select,
input[type="text"]:not(#Search-2),
input[type="tel"],
input[type="email"] {
	height: 38px;
	padding: 8px 12px;
	margin-bottom: 10px;
}

textarea,
input[type="text"]:not(#Search-2),
input[type="tel"],
input[type="email"] {
	width: 100%;
	font-size: 14px;
	line-height: 1.428;
}

select,
textarea,
input[type="text"]:not(#Search-2),
input[type="tel"],
input[type="email"] {
	display: block;
	color: #333333;
	border: 1px solid #666;
	font-size: 14px;
	line-height: 1.428;
}

.footer-text a {
	text-decoration: none;
	color: #333;
}

table.styled thead th,
table.styled tbody th,
table.styled th a,
table.styled.striped th a {
	font-weight: inherit;
	color: #fff;
	font-size: 1rem;
	line-height: 1.2rem;
	text-align: left;
}

table.styled td,
table.styled.striped td {
	font-size: 0.96rem;
	padding: 0.375rem 0.625rem;
}

table.styled th a:hover,
table.styled.striped th a:hover {
	text-decoration: none;
}

table.styled thead th {
	padding: 0.625rem 0.625rem;
	background-color: #00457b;
}

table.styled tbody th {
	padding: 0.5rem 0.625rem;
	background-color: #01345c;
}

table.styled.striped tr:nth-child(even) td {
	background: #dddddd;
}

table.styled.striped tr:nth-child(odd) td {
	background: #ffffff;
}

.controller-pages-sitemap .main-content-section a {
	color: #0078d6;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	background-color: transparent;
}
/* End custom styles */
